<template>
  <ContentBlock>
    <div class="subpage__title heading-3-sb grey-100">
      <template>
        {{ $t('Main.Ui.btStartPageProjects') }}
      </template>

      <SlSelectionInfo v-if="selectedRowIds.length">
        <template #description>
          {{ $t('Web.Selection', { 1: selectedRowIds.length }) }}
        </template>
        <template #actions>
          <SlButton
            variant="text"
            color="grey"
            @click="resetSelected"
          >
            <template #prepend>
              <icon
                class="fill-off size-20 color-grey-80"
                data="@icons/close.svg"
              />
            </template>
            {{ $t('Web.Deselect') }}
          </SlButton>
          <SlButton
            v-if="!isLoadingSelected"
            variant="secondary"
            color="grey"
            @click="callBulkEditAction(handleProjectArchive)"
          >
            <template #prepend>
              <icon
                class="fill-off size-20 color-grey-80"
                data="@icons/archive-project.svg"
              />
            </template>
            {{ $t('Web.Project.Archive') }}
          </SlButton>
          <SlButton
            v-if="canStopProjects"
            color="accent-red"
            @click="callBulkEditAction(handleProjectStop)"
          >
            <template #prepend>
              <icon
                class="fill-off size-20 color-white-stroke"
                data="@icons/stop-project.svg"
              />
            </template>
            {{ $t('Web.Project.Stop') }}
          </SlButton>
          <SlButton
            v-if="canRunProjects"
            @click="callBulkEditAction(handleProjectStart)"
          >
            <template #prepend>
              <icon
                class="fill-off size-20 color-white-stroke"
                data="@icons/start-project.svg"
              />
            </template>
            {{ $t('Web.Project.Run') }}
          </SlButton>
        </template>
      </SlSelectionInfo>
      <div
        v-if="$sl_isUserAdmin && !selectedRowIds.length"
        class="subpage__title-actions"
      >
        <SlButton
          v-if="!isFreePlan"
          variant="secondary"
          color="grey"
          @click="showUploadModal"
        >
          {{ $t('Web.Project.Label.Upload') }}
        </SlButton>
        <SlButton @click="handleCreateProject">
          {{ $t('Web.Project.Label.Create') }}
          <template
            v-if="isRestrictedFromAddingProject"
            #append
          >
            <icon
              data="@icons/upgrade-plan.svg"
              class="fill-off size-16"
            />
          </template>
        </SlButton>
      </div>
    </div>
    <div class="subpage__tabs">
      <SlTabList
        v-model="listTab"
        :tabs="listTabsConfig"
        horizontal
      />
    </div>
    <div
      class="subpage__table-wrapper"
      :class="{
        'subpage__table-wrapper--alert': isAlertShown
      }"
    >
      <SlTabContent
        :value="listTabsConfig[0].value"
        :tab-value="listTab"
        full-height
      >
        <div
          v-if="!isFreePlan"
          class="subpage__actions"
        >
          <SlSearchInput
            v-model="search"
            class="w-280"
            @submit="handleSearch"
          />
          <SlDropdown placement="bottom-start">
            <template #target="{ open }">
              <SlButton
                variant="secondary"
                color="grey"
                icon
                @click="open"
              >
                <icon
                  data="@icons/vertical-dots.svg"
                  class="size-20 color-grey"
                />
              </SlButton>
            </template>
            <template #content>
              <SlDropdownOption v-if="$sl_isUserAdmin">
                <template #prepend>
                  <SlCheckbox
                    id="bulk-edit"
                    v-model="bulkEdit"
                    toggle
                  />
                </template>
                <label for="bulk-edit">
                  {{ $t('Web.Project.BulkEdit') }}
                </label>
              </SlDropdownOption>
              <SlDropdownOption>
                <template #prepend>
                  <SlCheckbox
                    id="running-filter"
                    :value="runningFilter"
                    toggle
                    @change="handleRunningFilter"
                  />
                </template>
                <label for="running-filter">
                  {{ $t('Web.Project.FilterRunning') }}
                </label>
              </SlDropdownOption>
            </template>
          </SlDropdown>
        </div>
        <div class="subpage__table">
          <SlTable
            id="permanent-project-list"
            ref="permanentProjectsTable"
            :headers="tableConfig.permanent.headers"
            header-unique-key="key"
            header-name-key="text"
            :rows="filteredProjects"
            :unique-key="tableConfig.permanent.uniqueKey"
            :max-height="1100"
            :initial-col-sizes="initialColSizes"
            :hidden-columns-keys="['id', 'progress', 'actionsFlags']"
            :row-height="40"
            :col-initial-width="40"
            :col-resize="false"
            search-by="name"
            :inner-no-data="innerTableNoData"
            :selectable="bulkEdit"
            list
          >
            <template #header-checkbox>
              <SlCheckbox
                :value="headerCheckboxValue"
                :indeterminate="isIndeterminate"
                @change="handleRowCheckedAll"
              />
            </template>
            <template #header-actionsFlags-last />

            <template #row-checkbox="{ rowId, row, rowIndex }">
              <div
                v-if="isCurrentProject(row)"
                v-tooltip.right="currentProjectTooltip"
                class="project-list__cell--active"
              />
              <SlCheckbox
                v-tooltip="getCheckboxTooltip(rowIndex)"
                :value="isRowSelected(rowId)"
                :disabled="isRowCheckboxDisabled(row)"
                @change="(val) => handleRowChecked(val, rowId)"
              />
            </template>
            <template #name="slotScope">
              <div
                v-if="!bulkEdit && isCurrentProject(slotScope.row)"
                v-tooltip.right="currentProjectTooltip"
                class="project-list__cell--active"
              />
              <div class="sl-table__cell-text">
                <SlLink
                  v-if="isRunningProject(slotScope.row)"
                  variant="table"
                  @click="handleOpenProject(slotScope.row)"
                >
                  {{ slotScope.cellValue }}
                </SlLink>
                <template v-else>
                  {{ slotScope.cellValue }}
                </template>
              </div>
            </template>
            <template #status="slotScope">
              <SlBadge :variant="getVariant(slotScope.row)">
                <template #text>
                  {{ getStatusLabel(slotScope.row) }}
                </template>
              </SlBadge>
            </template>
            <template #actionsFlags-last="slotScope">
              <ProjectActionsDropdown
                v-if="+slotScope.cellValue"
                :project="slotScope.row"
                :actions="+slotScope.cellValue"
                @action="handleDropdownActionCalled"
              />
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataIcon"
                    :class="{
                      'illustration-md': isNoProjects,
                      'size-48': !isNoProjects,
                      'fill-off': !isNoProjects
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataTitle }}
                </template>
                <template #text>
                  {{ noDataText }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </SlTabContent>
      <SlTabContent
        v-if="listTabsConfig[1]"
        :value="listTabsConfig[1].value"
        :tab-value="listTab"
        full-height
      >
        <SlAlertLine
          v-if="temporaryProjects.length"
          type="info"
          :accent="$t('Web.Project.TemporaryAlert')"
        />
        <div class="subpage__table">
          <SlTable
            id="temporary-project-list"
            :headers="tableConfig.temporary.headers"
            header-unique-key="key"
            header-name-key="text"
            :rows="temporaryProjects"
            :unique-key="tableConfig.temporary.uniqueKey"
            :initial-col-sizes="['calc(100% - 40px)', '', '', '40px']"
            :hidden-columns-keys="['id', 'status', 'type', 'actionsFlags']"
            :max-height="1100"
            :row-height="40"
            :col-initial-width="40"
            :col-resize="false"
            list
          >
            <template #header-actionsFlags-last />

            <template #name="slotScope">
              <div
                v-if="isCurrentProject(slotScope.row)"
                class="project-list__cell--active"
              />
              <div class="sl-table__cell-text">
                <SlLink
                  v-if="isRunningProject(slotScope.row)"
                  variant="table"
                  @click="handleOpenProject(slotScope.row)"
                >
                  {{ slotScope.cellValue }}
                </SlLink>
                <template v-else>
                  {{ slotScope.cellValue }}
                </template>
              </div>
            </template>
            <template #actionsFlags-last="slotScope">
              <ProjectActionsDropdown
                v-if="+slotScope.cellValue"
                :project="getProjectBySlot(slotScope.row)"
                :actions="+slotScope.cellValue"
              />
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    data="@icons/illustration/no-projects.svg"
                    class="illustration-md"
                  />
                </template>
                <template #title>
                  {{ $t('Web.Project.Label.Empty') }}
                </template>
                <template #text>
                  {{ $t('Web.Project.Label.EmptyDesc') }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </SlTabContent>
    </div>
    <UploadModal
      :id="modalsId.UPLOAD_FILE"
      :title="$t('Web.Project.Label.Upload')"
      :file-format="fileFormats.gsl"
      :upload-callback="uploadProject"
      @enter="(cb) => handleRun(cb)"
    >
      <template #footer="{ handleCancel, handleUpload, newFile }">
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          variant="secondary"
          color="primary"
          :disabled="!newFile"
          @click="handleUploadProject(handleUpload)"
        >
          {{ $t('Web.Project.Upload') }}
        </SlButton>
        <SlButton
          :disabled="!newFile"
          @click="handleRun(handleUpload)"
        >
          {{ $t('Web.Project.UploadRun') }}
        </SlButton>
      </template>
    </UploadModal>
  </ContentBlock>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProjectActionsDropdown from './ProjectActionsDropdown.vue';
import UploadModal from '@/components/Modals/UploadModal.vue';
import { modal } from '@/mixins/modal';
import { project } from '@/mixins/project';
import modalsId from '@/config/shared/modalsId.config';
import {
  projectTableConfig,
  projectStatuses,
  projectListTabsConfig,
  runningStatuses,
  projectTypes, projectActionTypes
} from '@/config/project';
import { routeNames } from '@/config/router/router.config';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'ProjectList',
  components: {
    UploadModal,
    ProjectActionsDropdown
  },
  mixins: [modal, project],
  data() {
    return {
      getTooltip,
      variantByStatus: {
        [projectStatuses.STOPPED]: 'default',
        [projectStatuses.RUNNING]: 'accent-green',
        [projectStatuses.LOADING]: 'primary',
        [projectStatuses.UNINITIALIZED]: 'accent-yellow'
      },
      listTab: null,
      modalsId,
      fileFormats,
      search: '',
      selectedRowIds: []
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      projects: (state) => state.manageProjects.projectList,
      runningFilter: (state) => state.manageProjects.filters.running,
      bulkEditFilter: (state) => state.manageProjects.filters.bulkEdit
    }),
    ...mapGetters({
      activeProjects: 'manageProjects/activeProjects',
      isFreePlan: 'initialization/isFreePlan'
    }),
    listTabsConfig() {
      return projectListTabsConfig(this);
    },
    permanentProjects() {
      return this.projects?.permanent || [];
    },
    filteredProjects() {
      return this.filterProjects(this.permanentProjects);
    },
    temporaryProjects() {
      return this.projects?.temporary || [];
    },
    tableConfig() {
      return projectTableConfig(this);
    },
    currentProjectTooltip() {
      return getTooltip(this.$t('Web.Project.Current'));
    },
    isAlertShown() {
      return this.listTab === this.listTabsConfig[1].value && this.temporaryProjects.length;
    },
    innerTableNoData() {
      return !!(this.search || this.runningFilter);
    },
    isNoProjects() {
      return !this.permanentProjects.length;
    },
    noDataIcon() {
      if (this.search) {
        return require('@icons/style_no_search_results_double.svg');
      }

      if (this.runningFilter) {
        return require('@icons/style_no_filter_results_double.svg');
      }

      return require('@icons/illustration/no-projects.svg');
    },
    noDataTitle() {
      if (this.search) {
        return this.$t('Web.NoData.SearchTitle');
      }

      if (this.runningFilter) {
        return this.$t('Web.NoData.FilterTitle');
      }

      return this.$t('Web.Project.Label.Empty');
    },
    noDataText() {
      if (this.search) {
        return this.$t('Web.NoData.SearchText');
      }

      if (this.runningFilter) {
        return this.$t('Web.NoData.FilterText');
      }

      return this.$t('Web.Project.Label.EmptyDesc');
    },
    initialColSizes() {
      return [`calc(75% - ${this.bulkEdit ? 48 : 0}px)`, '25%', '40px'];
    },
    isLoadingSelected() {
      return this.selectedRowIds.some(id => {
        return this.permanentProjects.some(project => project.id === id && project.status === projectStatuses.LOADING);
      });
    },
    canStopProjects() {
      if (this.isLoadingSelected) {
        return false;
      }

      return !this.selectedRowIds.some(id => {
        return this.permanentProjects.some(project => project.id === id && project.status === projectStatuses.STOPPED);
      });
    },
    canRunProjects() {
      if (this.isLoadingSelected) {
        return false;
      }

      return !this.selectedRowIds.some(id => {
        return this.permanentProjects.some(project => project.id === id && project.status === projectStatuses.RUNNING);
      });
    },
    isIndeterminate() {
      return !!this.selectedRowIds.length && this.selectedRowIds.length !== this.permanentProjects.length;
    },
    headerCheckboxValue() {
      return !!this.selectedRowIds.length || this.isIndeterminate;
    },
    bulkEdit: {
      get() {
        return this.bulkEditFilter;
      },
      set(value) {
        this.updateFilter({
          key: 'bulkEdit',
          value
        });
      }
    }
  },
  watch: {
    listTab() {
      this.search = '';
    },
    bulkEdit() {
      this.resetSelected();
    }
  },
  methods: {
    ...mapActions({
      uploadProject: 'manageProjects/uploadProject',
      handleProjectStart: 'manageProjects/handleProjectStart',
      handleProjectStop: 'manageProjects/handleProjectStop',
      handleProjectArchive: 'manageProjects/handleProjectArchive',
      updateFilter: 'manageProjects/updateFilter',
      openProject: 'manageProjects/openProject'
    }),
    callBulkEditAction(action) {
      if (!this.selectedRowIds.length) {
        return;
      }

      action({ id: [...this.selectedRowIds] });

      this.resetSelected();
    },
    handleDropdownActionCalled({ actionType, project }) {
      if (actionType === projectActionTypes.ARCHIVE) {
        this.selectedRowIds = this.selectedRowIds.filter(id => id !== project.id);
      }
    },
    filterProjects(projects = []) {
      if (this.runningFilter) {
        return projects.filter(({ status }) => status === projectStatuses.RUNNING);
      }

      return projects;
    },
    showUploadModal() {
      if (this.isRestrictedFromAddingProject) {
        return this.showModal(modalsId.UPGRADE_PLAN);
      }

      this.showModal(modalsId.UPLOAD_FILE);
    },
    getProjectBySlot({ id }) {
      return this.activeProjects.find(project => project.id === id) || {};
    },
    async handleRun(uploadCallback) {
      const id = await uploadCallback();

      if (!id) {
        return;
      }

      this.handleProjectStart({ id });

      this.hideModal(modalsId.UPLOAD_FILE);
    },
    async handleUploadProject(uploadCallback) {
      await uploadCallback();

      this.hideModal(modalsId.UPLOAD_FILE);
    },
    async handleRunningFilter(value) {
      this.handleSearch('');

      await this.updateFilter({
        key: 'running',
        value
      });

      this.selectedRowIds = this.filteredProjects.reduce((acc, { id }) => {
        if (this.selectedRowIds.includes(id)) {
          acc.push(id);
        }

        return acc;
      }, []);
    },
   
    getStatusLabel({ status, progress }) {
      return this.$t(`Web.Project.Status.${status}`, { 1: progress?.current || 0 });
    },
    isRunningProject({ status }) {
      return runningStatuses.includes(status);
    },
    isCurrentProject({ id }) {
      return this.projectId === id;
    },
    handleOpenProject({ id: pid, status }) {
      const isUninitialized = status === projectStatuses.UNINITIALIZED;

      this.openProject({
        pid,
        type: isUninitialized
          ? projectTypes.UNINITIALIZED
          : projectTypes.PERMANENT,
        redirect: isUninitialized
          ? routeNames.INTEGRATIONS
          : undefined
      });
    },
    getVariant({ status }) {
      return `${this.variantByStatus[status] || ''}`;
    },
    handleSearch(query) {
      this.search = query;

      this.$refs.permanentProjectsTable.submitLocalSearch(query);
    },
    // row selection
    isRowSelected(rowId) {
      return this.selectedRowIds.includes(rowId);
    },
    isRowCheckboxDisabled({ status }) {
      return status === projectStatuses.UNINITIALIZED;
    },
    resetSelected() {
      this.$refs.permanentProjectsTable?.resetSelected();

      this.selectedRowIds = [];
    },
    handleRowChecked(checked, id) {
      if (checked) {
        this.selectedRowIds.push(id);
      } else {
        this.selectedRowIds = this.selectedRowIds.filter(rowId => rowId !== id);
      }
    },
    handleRowCheckedAll(checked) {
      if (checked) {
        // sl table search filtered rows
        const rows = this.$refs?.permanentProjectsTable?.tableRows ?? this.filteredProjects;

        this.selectedRowIds = rows.reduce((acc, { id, status }) => {
          if (status !== projectStatuses.UNINITIALIZED) {
            acc.push(id);
          }

          return acc;
        }, []);
      } else {
        this.selectedRowIds = [];
      }
    },
    getCheckboxTooltip(rowIndex) {
      return this.filteredProjects[rowIndex]?.status === projectStatuses.UNINITIALIZED
        ? getTooltip(this.$t('Web.Project.ProjectCantBeSelected'))
        : '';
    }
  }
};
</script>
